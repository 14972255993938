import React, { useState, useEffect, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import './HomeBattleSlider.scss'
import BattleCard from '../../../Component/BattelCard/BattleCard'
import { Button } from 'react-bootstrap';
import { ViewAllIcon } from '../../../Component/Icons';
import * as cs from '../../../Api/cs'
import useHeader from '../../../Helper/useHeader';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { updateBattleId } from '../../../Redux/Slices/battle.slice';
import { NextArrow, PreviousArrow } from '../../../Component/Icons';
import { updateShowChat } from '../../../Redux/Slices/chat.slice';
import { updateWalletShow } from '../../../Redux/Slices/modal.slice';
import { updateIsBadgeInfo, updateOpenDirectly } from '../../../Redux/Slices/badge.slice';
const HomeBattleSlider = () => {

    const headers = useHeader()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [sort, setSort] = useState('dsc')
    const [filter, setFilter] = useState('abcd')

    const [battleList, setBattleList] = useState([])
    const prevRef = useRef(null);
    const nextRef = useRef(null);

    const { userId } = useSelector(state => state.user)

    const [trendingBattle, setTrendingBattle] = useState([])

    useEffect(() => {
        if (userId == null) {
            getTrendingBattle()
        }
        else if (userId != null) {
            getRecommendedBattles()
        }

    }, [])


    const getTrendingBattle = async () => {
        const response = await cs.get('trendingBattles', 'battel', null, headers)
        setTrendingBattle(response?.data?.data)
    }

    const getRecommendedBattles = async () => {
        const response = await cs.get('getRecommendedBattles', 'battel', null, headers)
        setTrendingBattle(response?.data?.data)
    }

    const handleBattleCard = (item) => {
        navigate(`/battle/${item}`, { state: { itemData: item, battles: trendingBattle } })
        dispatch(updateBattleId(item))
    }



    const userTrendingBattles = userId ? trendingBattle?.filter(item => {
        return item.battlecreatedBy._id !== userId
    }) || [] : trendingBattle;

    console.log('userTrendingBattles', userTrendingBattles)
    return (
        <>
            {userTrendingBattles && userTrendingBattles.length > 0 && <div className={`battle-slider-wrapper ${userId !== null ? 'battle-spacing' : ""}`}>
                <div className='main-wrapper'>
                    <div className='main-container'>
                        {/* {userId && <h1>Recommended Battles</h1>} */}
                        <div className='battle-slider-btn-wrap'>
                            {userId && <h1>Recommended Battles</h1>}
                            <button className='btn' ref={prevRef}><PreviousArrow /></button>
                            <button className='btn' ref={nextRef}><NextArrow /></button>
                        </div>
                    </div>
                    <div className='battle-slider'>
                        <Swiper
                            spaceBetween={20}
                            modules={[Navigation]}
                            navigation={{
                                prevEl: prevRef.current,
                                nextEl: nextRef.current,
                            }}
                            onBeforeInit={(swiper) => {
                                // Here, we set the navigation buttons before Swiper initializes
                                swiper.params.navigation.prevEl = prevRef.current;
                                swiper.params.navigation.nextEl = nextRef.current;
                                swiper.navigation.init();
                                swiper.navigation.update();
                            }}

                            breakpoints={{
                                1500: {
                                    slidesPerView: 3.9
                                },
                                1250: {
                                    slidesPerView: 2.9
                                },
                                768: {
                                    slidesPerView: 1.9,

                                },
                                300: {
                                    enabled: false
                                }
                            }}

                        >

                            {userTrendingBattles && userTrendingBattles?.length > 0 && userTrendingBattles.map((item) => (
                                <SwiperSlide key={item?._id} onClick={() => { handleBattleCard(item?._id); dispatch(updateShowChat(false)); dispatch(updateWalletShow(false)); dispatch(updateIsBadgeInfo(false)); dispatch(updateOpenDirectly(false)) }}><BattleCard battleId={item?._id} type={item?.battleOpponentStatus ? null : 'awaiting'} /></SwiperSlide>
                            ))}

                        </Swiper>

                        <div className='viewall-btn-wrap'
                            onClick={() => {
                                navigate('/battle/all/Recommended', {
                                    state: {
                                        type: userId === null ? 'trendingBattle' : "battle",
                                        title: userId === null ? 'Trending Battles' : "Recommended Battles",
                                    },
                                });
                            }}
                        ><Button variant='secondary'><ViewAllIcon /> View all</Button></div>
                    </div>
                </div>
            </div>
            }

        </>
    )
}

export default HomeBattleSlider