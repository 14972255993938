import React, { useState, useRef, useEffect } from 'react'
import ProgressBar from 'react-bootstrap/ProgressBar';
import AudioThumbnail from '../../assets/images/audio-thumbnail.png'
import useHeader from '../../Helper/useHeader';
import './Player.scss'
import * as cs from '../../Api/cs'
import { bucketUrl } from '../../Helper/Constant';
import { useDispatch, useSelector } from 'react-redux';
import { updateArtistId, updateIsPlaying, updateTrack, updateTrackList, updateTrackProgress } from '../../Redux/Slices/player.slice';
import { ArrowDownIcon, ColoredStarIcon, StarIcon } from '../Icons';
import { useNavigate } from 'react-router-dom';

const Player = () => {

  const { artistId, track, progress, isPlaying, playerFocus, playerSpaceBarRead, trackList } = useSelector(state => state.player)
  const dispatch = useDispatch();

  const progressBarRef = useRef(null);

  const navigate = useNavigate()
  const { showPlayer } = useSelector(state => state.player)

  const audioRef = useRef(null);

  const headers = useHeader()
  const [trackList1, setTrackList1] = useState([])
  const [currentTrackIndex, setCurrentTrackIndex] = useState(null);
  const [isShuffle, setIsShuffle] = useState(false);
  const [isPlayerExpanded, setIsPlayerExpanded] = useState(true);
  const [isFav, setIsFav] = useState(false)
  const [currentTime, setCurrentTime] = useState(0);


  let checkPlayPause = isPlaying;
  const { userId } = useSelector(state => state.user)

  useEffect(() => {
    const handleGlobalKeyDown = (event) => {
      // Check if the event target is an input field, textarea, or contenteditable element
      const isInputOrTextareaOrContentEditable = ['INPUT', 'TEXTAREA'].includes(event.target.tagName) || event.target.isContentEditable;

      if (!isInputOrTextareaOrContentEditable) {
        if (event.code === 'Space') {
          event.preventDefault();
          togglePlay();
        } else if (event.code === 'ArrowLeft') {
          event.preventDefault();
          audioRef.current.currentTime -= 10;
        } else if (event.code === 'ArrowRight') {
          event.preventDefault();
          audioRef.current.currentTime += 10;
        }
      }
    };

    window.addEventListener('keydown', handleGlobalKeyDown);

    return () => {
      window.removeEventListener('keydown', handleGlobalKeyDown);
    };
  }, []);


  useEffect(() => {

    if (showPlayer && isPlayerExpanded) {
      document.body.classList.add('showPlayer');
    } else {
      document.body.classList.remove('showPlayer');
    }
  }, [showPlayer, isPlayerExpanded]);



  const togglePlay = () => {
    if (audioRef.current.paused) {
      audioRef.current.play();
      dispatch(updateIsPlaying(true));
    } else {
      audioRef.current.pause();
      dispatch(updateIsPlaying(false));
    }
  };

  useEffect(() => {
    // if (audioRef.current) {
    //   audioRef.current.pause();
    //   dispatch(updateIsPlaying(false));
    // }
    if (userId != null) {
      favoriteCheck()
    }
    if (trackList && trackList.length == 0) {
      getTrackListOfArtist()
    }

    addTrackCount()
  }, []);

  const favoriteCheck = async () => {

    const response = await cs.get('list/' + userId + '/' + track?._id, 'myFav', {}, headers)
    if (response?.data?.value === true) {
      setIsFav(true)
    }
    else if (response?.data?.value === false) {
      setIsFav(false)
    }

  }

  useEffect(() => {
    if (isPlaying === false) {
      audioRef.current.pause();
    }
    else {
      audioRef.current.play();
    }
  }, [checkPlayPause])


  const addTrackCount = async () => {

    if (userId !== track?.createdBy?._id) {
      const response = await cs.get('count/' + track?._id, 'track', {}, headers)

    }
  }

  useEffect(() => {

    const currentAudio = audioRef.current;


    favoriteCheck()

    const loadAudio = async () => {
      try {
        if (!isPlaying) {
          audioRef.current.pause();
        }

        currentAudio.src = track?.track_video;

        if (currentAudio.src) {
          await currentAudio.load();
          setTimeout(() => {
            if (isPlaying) {
              audioRef.current.play();
            }
          }, 2000)
        }
      } catch (error) {

      }
    };

    loadAudio();




  }, [track]);

  const getTrackListOfArtist = async () => {
    const response = await cs.get('getById/' + artistId, 'track', {}, headers)
    setTrackList1(response?.data?.data)
    dispatch(updateTrackList(response?.data?.data))

  }
  console.log('updateTrackList trackList', trackList)
  const playNextTrack = () => {
    const nextIndex = (currentTrackIndex + 1) % trackList?.length;

    if (nextIndex > -1 && !isNaN(nextIndex)) {
      setCurrentTrackIndex(nextIndex);
      playTrack(nextIndex);
      dispatch(updateIsPlaying(true));
    }

  };

  const playPreviousTrack = () => {
    if (audioRef.current.currentTime >= 3) {
      // Restart the current track
      audioRef.current.currentTime = 0;
      audioRef.current.play();
    } else {
      // Move to the previous track
      const prevIndex = (currentTrackIndex - 1 + trackList?.length) % trackList?.length;
      if (prevIndex > -1 && !isNaN(prevIndex)) {
        setCurrentTrackIndex(prevIndex);
        playTrack(prevIndex);
        dispatch(updateIsPlaying(true));
      }
    }
  };


  const playTrack = (index) => {
    const current_track = trackList[index];
    dispatch(updateArtistId(current_track?.createdBy?._id))
    dispatch(updateTrack(current_track));

  };

  // const resetMusic = () => {
  //   dispatch(updateIsPlaying(false));
  //   audioRef.current.pause();
  //   audioRef.current.currentTime = 0
  //   dispatch(updateTrackProgress(0))
  //   setTimeout(() => {
  //     dispatch(updateIsPlaying(true));
  //     audioRef.current.play();
  //   }, 2000);
  // };


  // const shuffleMusic = () => {
  //   const shuffledTracks = [...trackList].sort(() => Math.random() - 0.5);
  //   setTrackList1(shuffledTracks);
  //   setCurrentTrackIndex(0);
  //   playTrack(0);
  //   setIsShuffle(true);
  // }

  const handleTrackEnd = () => {
    if (!isShuffle) {
      playNextTrack();
    } else {
      const nextIndex = (currentTrackIndex + 1) % trackList?.length;
      setCurrentTrackIndex(nextIndex);
      playTrack(nextIndex);
    }
  };


  // comment because it play always the first index song for each user not particular 
  // useEffect(() => {
  //   if (trackList?.length > 0) {
  //     playTrack(currentTrackIndex);
  //   }
  // }, [trackList]);

  useEffect(() => {
    const updateProgress = () => {
      const duration = audioRef?.current?.duration;
      const currentTime = audioRef?.current?.currentTime;
      const progressPercentage = (currentTime / duration) * 100;


      dispatch(updateTrackProgress(progressPercentage));
      setCurrentTime(currentTime);
    };

    audioRef?.current?.addEventListener('timeupdate', updateProgress);

    return () => {
      audioRef?.current?.removeEventListener('timeupdate', updateProgress);
    };
  }, []);



  const togglePlayerSize = () => {

    setIsPlayerExpanded(!isPlayerExpanded);

  };


  const addToFavorite = async () => {

    if (userId == null) {
      return
    }
    else if (userId != null) {
      const _data = {
        "trackId": track?._id,
        "trackName": track?.track_name,
        "userId": userId
      }


      const response = await cs.post('add', 'myFav', _data, null, headers)
      setIsFav(response?.data?.status)
    }

  }

  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = Math.floor(timeInSeconds % 60);
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  const handleTrackChange = (event) => {
    if (progressBarRef.current) {
      const progressBarWidth = progressBarRef.current.offsetWidth;
      const clickX = event.nativeEvent.offsetX;
      const duration = audioRef.current.duration;
      const newTime = (clickX / progressBarWidth) * duration;
      audioRef.current.currentTime = newTime;
      setCurrentTime(newTime);
      dispatch(updateTrackProgress((newTime / duration) * 100));
    }
  };

  return (
    <>


      <div className={`player ${isPlayerExpanded ? '' : 'collapsed'}`} >
        <div className={`player-collapse-icon-wrap ${isPlayerExpanded ? '' : 'collapsed'}`} onClick={togglePlayerSize}><ArrowDownIcon /></div>
        <div className="player-info-wrap">

          <div className="player-info-thumbnail cursor-pointer" onClick={() => { navigate(`/track/${track?._id}`, { state: { trackId: track?._id } }) }}>
            <img src={track?.track_image ? track?.track_image : AudioThumbnail} />
          </div>
          <div className="player-info">
            <div className="author-name cursor-pointer" onClick={() => { navigate(`/profile/${track?.createdBy?._id}`, { state: { type: "user", id: track?.createdBy?._id } }) }}>
              {track?.createdBy?.name ? track?.createdBy?.name : track?.createdBy?.userName}</div>
            <div className="song-title cursor-pointer" onClick={() => { navigate(`/track/${track?._id}`, { state: { trackId: track?._id } }) }}>{track?.track_name}</div>

          </div>
        </div>
        <div className="player-control-wrap">
          <div className="player-control">
            {/* <div id="track" onClick={shuffleMusic}>
              {isShuffle ? <svg xmlns="http://www.w3.org/2000/svg" width="17" height="19" viewBox="0 0 17 19" fill="none">
                <path fill-rule="evenodd" clipRule="evenodd" d="M6.3392 9.13533C5.99476 8.04156 5.33405 7.09026 4.45064 6.41615C3.56723 5.74205 2.50591 5.37932 1.41699 5.37933V3.87933C2.80324 3.8794 4.15433 4.34126 5.2789 5.19951C6.40347 6.05775 7.24451 7.26888 7.68291 8.66133L7.82812 9.12333C8.13421 10.0956 8.69105 10.9578 9.4357 11.6126C10.1804 12.2673 11.0831 12.6884 12.042 12.8283V10.3188L15.1686 13.6293L12.042 16.9398V14.3396C10.7839 14.1958 9.59138 13.6724 8.60614 12.8317C7.6209 11.991 6.88446 10.8684 6.48441 9.59733L6.3392 9.13533Z" fill="red" />
                <path fill-rule="evenodd" clipRule="evenodd" d="M6.11324 11.2203C5.69451 12.1647 5.03049 12.9633 4.19883 13.5227C3.36718 14.0821 2.40228 14.3793 1.41699 14.3793V15.8793C2.52811 15.8793 3.62126 15.5825 4.5953 15.0164C5.56934 14.4504 6.39279 13.6333 6.98945 12.6408C6.64411 12.2068 6.3494 11.7298 6.11324 11.2203ZM12.042 6.93035C10.8614 7.10287 9.77293 7.70033 8.95933 8.62235C8.7873 8.30509 8.64564 7.97043 8.53645 7.62335L8.39124 7.16135L8.38062 7.12835C9.4048 6.17157 10.684 5.57465 12.042 5.41985V2.81885L15.1686 6.12935L12.042 9.43985V6.93035Z" fill="red" />
              </svg> :
                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="19" viewBox="0 0 17 19" fill="none">
                  <path fill-rule="evenodd" clipRule="evenodd" d="M6.3392 9.13533C5.99476 8.04156 5.33405 7.09026 4.45064 6.41615C3.56723 5.74205 2.50591 5.37932 1.41699 5.37933V3.87933C2.80324 3.8794 4.15433 4.34126 5.2789 5.19951C6.40347 6.05775 7.24451 7.26888 7.68291 8.66133L7.82812 9.12333C8.13421 10.0956 8.69105 10.9578 9.4357 11.6126C10.1804 12.2673 11.0831 12.6884 12.042 12.8283V10.3188L15.1686 13.6293L12.042 16.9398V14.3396C10.7839 14.1958 9.59138 13.6724 8.60614 12.8317C7.6209 11.991 6.88446 10.8684 6.48441 9.59733L6.3392 9.13533Z" fill="white" />
                  <path fill-rule="evenodd" clipRule="evenodd" d="M6.11324 11.2203C5.69451 12.1647 5.03049 12.9633 4.19883 13.5227C3.36718 14.0821 2.40228 14.3793 1.41699 14.3793V15.8793C2.52811 15.8793 3.62126 15.5825 4.5953 15.0164C5.56934 14.4504 6.39279 13.6333 6.98945 12.6408C6.64411 12.2068 6.3494 11.7298 6.11324 11.2203ZM12.042 6.93035C10.8614 7.10287 9.77293 7.70033 8.95933 8.62235C8.7873 8.30509 8.64564 7.97043 8.53645 7.62335L8.39124 7.16135L8.38062 7.12835C9.4048 6.17157 10.684 5.57465 12.042 5.41985V2.81885L15.1686 6.12935L12.042 9.43985V6.93035Z" fill="white" />
                </svg>
              }
            </div> */}
            <div id="previous" onClick={playPreviousTrack}>
              <svg xmlns="http://www.w3.org/2000/svg" width="13" height="12" viewBox="0 0 13 12" fill="none">
                <path d="M12.21 0.161848C12.2848 0.108616 12.3727 0.0769901 12.4643 0.0704334C12.5558 0.0638767 12.6474 0.0826428 12.729 0.124676C12.8106 0.166708 12.879 0.230386 12.9268 0.308732C12.9746 0.387078 12.9999 0.47707 13 0.568848V10.5688C12.9999 10.6606 12.9746 10.7506 12.9268 10.829C12.879 10.9073 12.8106 10.971 12.729 11.013C12.6474 11.0551 12.5558 11.0738 12.4643 11.0673C12.3727 11.0607 12.2848 11.0291 12.21 10.9758L5.21 5.97585C5.14518 5.9296 5.09235 5.86853 5.0559 5.79774C5.01945 5.72695 5.00043 5.64847 5.00043 5.56885C5.00043 5.48922 5.01945 5.41075 5.0559 5.33995C5.09235 5.26916 5.14518 5.2081 5.21 5.16185L12.21 0.161848ZM1 11.0688H0V0.0688477H1V11.0688Z" fill="white" />
              </svg>
            </div>
            <div id="playpause" onClick={togglePlay}
            >
              {isPlaying ?
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                  <path d="M15.125 1.375V15.125C15.125 15.4897 14.9801 15.8394 14.7223 16.0973C14.4644 16.3551 14.1147 16.5 13.75 16.5H10.3125C9.94783 16.5 9.59809 16.3551 9.34023 16.0973C9.08237 15.8394 8.9375 15.4897 8.9375 15.125V1.375C8.9375 1.01033 9.08237 0.660591 9.34023 0.402728C9.59809 0.144866 9.94783 0 10.3125 0H13.75C14.1147 0 14.4644 0.144866 14.7223 0.402728C14.9801 0.660591 15.125 1.01033 15.125 1.375ZM4.8125 0H1.375C1.01033 0 0.660591 0.144866 0.402728 0.402728C0.144866 0.660591 0 1.01033 0 1.375V15.125C0 15.4897 0.144866 15.8394 0.402728 16.0973C0.660591 16.3551 1.01033 16.5 1.375 16.5H4.8125C5.17717 16.5 5.52691 16.3551 5.78477 16.0973C6.04263 15.8394 6.1875 15.4897 6.1875 15.125V1.375C6.1875 1.01033 6.04263 0.660591 5.78477 0.402728C5.52691 0.144866 5.17717 0 4.8125 0Z" fill="white" />
                </svg> :
                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="19" viewBox="0 0 17 19" fill="none">
                  <path d="M16.6855 9.56882L0.656898 18.9498V0.187818L16.6855 9.56882Z" fill="white" />
                </svg>
              }
            </div>
            <div id="next" onClick={playNextTrack} >
              <svg xmlns="http://www.w3.org/2000/svg" width="15" height="16" viewBox="0 0 15 16" fill="none">
                <path d="M1.79 2.16185C1.71524 2.10862 1.62726 2.07699 1.53572 2.07043C1.44418 2.06388 1.3526 2.08264 1.27101 2.12468C1.18942 2.16671 1.12098 2.23039 1.07318 2.30873C1.02537 2.38708 1.00006 2.47707 1 2.56885V12.5688C1.00006 12.6606 1.02537 12.7506 1.07318 12.829C1.12098 12.9073 1.18942 12.971 1.27101 13.013C1.3526 13.0551 1.44418 13.0738 1.53572 13.0673C1.62726 13.0607 1.71524 13.0291 1.79 12.9758L8.79 7.97585C8.85482 7.9296 8.90765 7.86853 8.9441 7.79774C8.98055 7.72695 8.99957 7.64847 8.99957 7.56885C8.99957 7.48922 8.98055 7.41075 8.9441 7.33995C8.90765 7.26916 8.85482 7.2081 8.79 7.16185L1.79 2.16185ZM13 13.0688H14V2.06885H13V13.0688Z" fill="white" />
              </svg>
            </div>
            {/* <div id="reset" onClick={resetMusic}>
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" fill="none">
                <path d="M10.125 16.3189C11.46 16.3189 12.7651 15.923 13.8751 15.1813C14.9851 14.4396 15.8503 13.3854 16.3612 12.152C16.8721 10.9186 17.0058 9.56136 16.7453 8.25199C16.4848 6.94262 15.842 5.73988 14.898 4.79588C13.954 3.85188 12.7512 3.209 11.4419 2.94855C10.1325 2.6881 8.77529 2.82177 7.54189 3.33266C6.30849 3.84356 5.25428 4.70872 4.51258 5.81875C3.77088 6.92878 3.375 8.23383 3.375 9.56885V13.0564L1.35 11.0314L0.5625 11.8189L3.9375 15.1939L7.3125 11.8189L6.525 11.0314L4.5 13.0564V9.56885C4.5 8.45633 4.8299 7.36879 5.44798 6.44377C6.06607 5.51874 6.94457 4.79777 7.97241 4.37203C9.00024 3.94629 10.1312 3.83489 11.2224 4.05193C12.3135 4.26898 13.3158 4.8047 14.1025 5.59138C14.8891 6.37805 15.4249 7.38032 15.6419 8.47147C15.859 9.56261 15.7476 10.6936 15.3218 11.7214C14.8961 12.7493 14.1751 13.6278 13.2501 14.2459C12.3251 14.8639 11.2375 15.1939 10.125 15.1939V16.3189Z" fill="white" />
              </svg>
            </div> */}
          </div>

          <div className='progress-wrapper'>
            <div className='progress-time-left'>{formatTime(currentTime)}</div>
            <div className='progress-time-right'>{track?.duration}</div>
            <div className="progress-bar-container" onClick={handleTrackChange} ref={progressBarRef}>
              <ProgressBar now={progress} />
            </div>
          </div>
          {/* <input type="range" min={0} max={audioRef?.current?.duration} value={progress} ref={progressBarRef} onChange={handleTrackChange}/> */}
        </div>
        <audio controls ref={audioRef} className="d-none" onEnded={handleTrackEnd}>
          {audioRef?.current?.src && <source src={audioRef?.current?.src} type="audio/mp3" />}
        </audio>
        <div className="player-rating-wrap">
          {userId && <div className='player-rating' onClick={addToFavorite}>

            {isFav ? <ColoredStarIcon /> : <StarIcon />}

          </div>
          }

        </div>


      </div>
    </>
  )
}

export default Player