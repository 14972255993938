import React, { useEffect, useRef, useState } from 'react'
import { Button, Modal } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { editTrackSchema } from '../../ValidationSchema/validationSchema';
import { CloseIcon, DeleteIcon, ImageIcon, TrackIcon } from '../../Component/Icons';
import CreatableSelect from 'react-select/creatable';
import * as cs from '../../Api/cs'
import useHeader from '../../Helper/useHeader';
import { useDropzone } from 'react-dropzone';
import { BallTriangle } from 'react-loader-spinner';

const EditTracKModal = ({ editTrack, handleClose, data , genreList, action = null}) => {
    const [image, setImage] = useState(null)
    const [selectedFile, setSelectedFile] = useState(null);
    const [inputValue, setInputValue] = useState('');
    const [tag, setTag] = useState([]);
    const [moodValue, setMoodValue] = useState('');
    const [mood, setMood] = useState([]);
    const [loading, setLoading] = useState(false);

    const headers = useHeader()

    const tagRef = useRef()
    const moodRef = useRef()

    const { control, handleSubmit, setValue, formState: { errors, isValid }, register, watch, useWatch, reset, clearErrors, trigger } = useForm({
        resolver: yupResolver(editTrackSchema(!image)),
        mode: 'onChange',
    });

    useEffect(() => {

        if (watch('mood')) {
            trigger('mood');
        }
        if (watch('tag')) {
            trigger('tag');
        }
    }, [watch('mood'), watch('tag')]);

    useEffect(() => {
        if (data) {
            setImage(data?.track_image)
            setValue('trackNameInput', data?.track_name)
            setValue('trackDescriptionInput', data?.track_description)
            const tags = data?.track_tags?.map(tag => ({ label: tag, value: tag }));
            setTag(tags);
            setValue('tag', tags?.map(tag => tag.value));
      
            // Set mood values
            const moods = data?.track_moods?.map(mood => ({ label: mood, value: mood }));
            setMood(moods);
            setValue('mood', moods?.map(mood => mood.value));
            // setValue('genreSelect', data?.track_genre?.label)
            setValue('genreSelect', data?.track_genre?._id)
        }
    }, [])


  

    const onSubmit = async (editData) => {
        setLoading(true)
        let imageUpload
        if(editData?.imageInpu)
        {
            const imgFormObj = new FormData()
            imgFormObj.append("data", editData?.imageInput?.[0])
            imageUpload = await cs.post('upload', 'util', imgFormObj, headers)
        }

        const track_data = {
            "track_image": imageUpload?.data?.data ? imageUpload?.data?.data : image,
            "track_name" : editData?.trackNameInput,
            "track_description" : editData?.trackDescriptionInput,
            "track_tags": editData?.tag,
            "track_moods" :editData?.mood,
            "track_genre": editData.genreSelect
        };



      
        const response = await cs.put('editTrack/'+data?._id, 'track', track_data, {}, headers)

        if(response?.data?.code == 200)
        {
            setLoading(false)
           handleClose()
           if(action!= null)
           {
            action()
           }
         
        }
    }

    const handleKeyDown = (event) => {
        if (event?.key === ' ' && inputValue?.trim() !== '') {
            // If the user presses space and the input is not empty
            const newTag = { label: inputValue?.trim(), value: inputValue?.trim() };
            const updatedTags = [...tag, newTag];
            setTag(updatedTags);
            setValue('tag', updatedTags?.map(tag => tag?.value));
            setInputValue('');
        }
    };

    const moodHandleKeyDown = (event) => {
        if (event?.key === ' ' && moodValue?.trim() !== '') {
            // If the user presses space and the input is not empty
            const newTag = { label: moodValue?.trim(), value: moodValue?.trim() };
            const updatedTags = [...mood, newTag];
            setMood(updatedTags);
            setValue('mood', updatedTags?.map(mood => mood?.value));
            setMoodValue('');
        }
    };

    useEffect(()=>{
        if(inputValue.endsWith(" "))
        {
            if (inputValue?.trim() !== '') {
                const newTag = { label: inputValue.trim(), value: inputValue.trim() };
                const updatedTags = [...tag, newTag];
                setTag(updatedTags);
                setValue('tag', updatedTags?.map(tag => tag.value));
                setInputValue('');
            }
        }
        if(moodValue.endsWith(" "))
    {
        if (moodValue?.trim() !== '') {
            const newTag = { label: moodValue.trim(), value: moodValue.trim() };
            const updatedTags = [...mood, newTag];
            setMood(updatedTags);
            setValue('mood', updatedTags?.map(mood => mood?.value));
            setMoodValue('');
        }
    }
      },[inputValue,moodValue])

      const handleImageSelect = (acceptedFiles) => {
        setValue('imageInput', acceptedFiles);
        setImage(URL.createObjectURL(acceptedFiles[0]));
        clearErrors('imageInput');
      };

    
      const { getRootProps: getRootPropsForImage, getInputProps: getInputPropsForImage } = useDropzone({
        accept: 'image/*',
        onDrop: handleImageSelect,
      });
    
      
      
    return (
        <Modal show={editTrack} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Edit Track</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            {loading && ( // Show loader if loading state is true
                        <div className="loader-container">
                            <div className="loader">
                                <BallTriangle
                                    type="TailSpin"
                                    color="#00BFFF"
                                    height={50}
                                    width={50}
                                />
                            </div>
                        </div>
                    )}
                <div className="w-100">
                    <form className='battle-form' onSubmit={handleSubmit(onSubmit)}>

                        <div className="add-new-track">
                            <>
                                <Form.Group className='file-upload-image  form-row' >
                                    {!image &&
                                        <label htmlFor="imageInput" {...getRootPropsForImage()} className='file-upload'>
                                            <ImageIcon />
                                            {/* <input type="file"
                                                id="imageInput"
                                                accept="image/*"
                                                onChange={(e) => {
                                                    setValue('imageInput', e.target.files);
                                                    setImage(URL.createObjectURL(e.target.files[0]));
                                                    clearErrors('imageInput');
                                                }} /> */}
                                                  <input {...getInputPropsForImage()} accept="image/*" />
                                            <div className='file-upload-text'>
                                                Choose image<br />
                                                or drop it here
                                            </div>
                                        </label>
                                    }
                                    {/*Image upload functionality done here */}

                                    {image &&
                                        <div className='image-upload-holder'>
                                            <img src={image} alt='user' />
                                            <div className='delete-img-wrap' onClick={() => setImage(null)}>
                                                <DeleteIcon />
                                            </div>
                                        </div>
                                    }

                                    {errors.imageInput && <div className="errorMsg">{errors.imageInput.message}</div>}
                                </Form.Group>

                                <Form.Group className='form-row' >
                                    <Form.Control type="text" id="trackNameInput" {...register('trackNameInput')} placeholder='Track Name' />
                                    {errors.trackNameInput && <div className="errorMsg">{errors.trackNameInput.message}</div>}
                                </Form.Group>
                                {/* 
                        <Form.Group className='form-row' >
                            <Form.Control type="textarea" id="trackDescriptionInput" {...register('trackDescriptionInput')} placeholder='Track Description' />
                            {errors.trackDescriptionInput && <div className="errorMsg">{errors.trackDescriptionInput.message}</div>}
                        </Form.Group> */}
{/* 
                                <Form.Group className='form-row'>
                                    <Form.Control as="textarea" id="trackDescriptionInput" {...register('trackDescriptionInput')} placeholder='Track Description' />
                                    {errors.trackDescriptionInput && <div className="errorMsg">{errors.trackDescriptionInput.message}</div>}
                                </Form.Group> */}


                                <Form.Group className='form-row'>
                                    {/* <Form.Control type="text" id="genreSelect" {...register('genreSelect')} readOnly /> */}
                                    <Form.Select id="genreSelect" {...register('genreSelect')}>
                                <option value="">Select Genre</option>
                                {genreList?.map((genre) => (
                                    <option key={genre?._id} value={genre?._id}>
                                        {genre?.label}
                                    </option>
                                ))}
                            </Form.Select>
                            {errors.genreSelect && <div className="errorMsg">{errors.genreSelect.message}</div>}
                                </Form.Group>


                                <Form.Group className="form-row react-select">
                                    <CreatableSelect
                                        className="react-select-container"
                                        classNamePrefix="react-select"
                                        inputValue={moodValue}
                                        isClearable
                                        isMulti
                                        {...register('mood')}
                                        menuIsOpen={false}
                                        onChange={(selectedTags, actionMeta) => {
                                            const selectedTagValues = selectedTags?.map(mood => mood.value);
                                            setMood(selectedTags);
                                            setValue('mood', selectedTagValues);
                                        }}
                                        onInputChange={(newValue) => setMoodValue(newValue)}
                                        // onKeyDown={moodHandleKeyDown}
                                        placeholder="Moods (3)"
                                        value={mood}
                                        ref={moodRef}
                                    />
                                    {errors?.mood && <p className="errorMsg">{errors?.mood?.message}</p>}
                                </Form.Group>

                                <Form.Group className="form-row react-select">
                                    <CreatableSelect
                                        className="react-select-container"
                                        classNamePrefix="react-select"
                                        inputValue={inputValue}
                                        isClearable
                                        isMulti
                                        {...register('tag')}
                                        menuIsOpen={false}
                                        onChange={(selectedTags, actionMeta) => {
                                            const selectedTagValues = selectedTags?.map(tag => tag.value);
                                            setTag(selectedTags);
                                            setValue('tag', selectedTagValues);
                                        }}
                                        onInputChange={(newValue) => setInputValue(newValue)}
                                        // onKeyDown={handleKeyDown}
                                        placeholder="Tags (3)"
                                        value={tag}
                                        ref={tagRef}
                                    />
                                    {errors?.tag && <p className="errorMsg">{errors?.tag?.message}</p>}
                                </Form.Group>



                            </>




                        </div>


                        <div className='edit-btn-wrap'>
                            <Button variant="secondary" type="button" onClick={handleClose}>
                                Cancel
                            </Button>
                            <Button variant="primary" type="submit">
                                Save Changes
                            </Button>
                        </div>
                    </form>
                </div>
            </Modal.Body>

        </Modal>
    )
}

export default EditTracKModal
