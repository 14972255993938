import React, { useEffect, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import './BattlesByGenre.scss'
import { Button } from 'react-bootstrap'
import BattleCard from '../../../Component/BattelCard/BattleCard';
import { ViewAllIcon } from '../../../Component/Icons';
import useHeader from '../../../Helper/useHeader';
import * as cs from '../../../Api/cs'
import { updateBattleId } from '../../../Redux/Slices/battle.slice';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

const BattlesByGenre = () => {
    const headers = useHeader()
    const [genreList, setGenreList] = useState([])
    const [activeGenre, setActiveGenre] = useState()
    const [battleList, setBattleList] = useState([])
    const [genre, setGenre] = useState(null)
    const navigate = useNavigate()
    const dispatch = useDispatch()

    useEffect(() => {
        getGenreList()
        getBattlesByGenre()
    }, [])

    useEffect(() => {
        getBattlesByGenre()
    }, [activeGenre])


    const getGenreList = async () => {
        const response = await cs.get('list', 'genre', {}, headers)
        setGenreList(response?.data?.data)
        setActiveGenre(response?.data?.data?.[0]?._id)
        setGenre(response?.data?.data?.[0])
    }

    const handleBattleGenre = (genre) => {
        setGenre(genre)
        setActiveGenre(genre?._id)
    }


    const getBattlesByGenre = async () => {

        const response = await cs.get('findBattlesByGenre/' + activeGenre, 'battel', {}, headers)
        setBattleList(response?.data?.data)
    }

    const handleBattleCard = (item) => {
        navigate(`/battle/${item}`, { state: { itemData: item, battles: battleList } })
        dispatch(updateBattleId(item))
    }

    
    return (
        <>
            <div className='battle-by-genre-wrapper'>
                <div className='main-wrapper'>

                    <div className='battle-by-genre'>
                        <div className='battle-by-genre-title-wrapper'>
                            <div className='battle-by-genre-title'>Battles by Genre </div>
                            <div className='btn-wrap' ><Button variant='secondary' onClick={() => navigate(`/genre/${ activeGenre}`, { state: { genre: activeGenre } })}>View All</Button></div>
                        </div>
                        <div className='genre-tag-wrap'>
                            {/* <div className='genre-tag active'>Genre</div> */}
                            {
                                genreList && genreList?.length > 0 && genreList.map((item) => (
                                    <div className={`genre-tag ${activeGenre === item?._id ? 'active' : ''}`} onClick={() => { handleBattleGenre(item) }}>{item?.label}</div>
                                ))
                            }


                        </div>

                        <div className='battle-by-genre-slider'>
                            <Swiper
                                spaceBetween={20}
                                breakpoints={{
                                    1500: {
                                        slidesPerView: 3.1
                                    },

                                    768: {
                                        slidesPerView: 2.1,

                                    },
                                    300: {
                                        enabled: false
                                    }
                                }}

                            >
                                {
                                    battleList && battleList?.length > 0 ?
                                        (
                                            battleList.map((item) => (
                                                <SwiperSlide key={item._id} onClick={() => { handleBattleCard(item._id) }}>
                                                    <BattleCard battleId={item._id} type={item?.battleOpponentStatus ? null : 'awaiting'} />
                                                </SwiperSlide>
                                            ))
                                        )
                                        :
                                        (
                                            <div style= {{color: "white"}}> No Battle With {genre?.label} Genre </div>
                                        )
                                }


                            </Swiper>
                            <div className='viewall-btn-wrap'><Button variant='secondary' onClick={() => navigate(`/genre/${activeGenre}`, { state: { genre: activeGenre } })}><ViewAllIcon /> View all</Button></div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BattlesByGenre