import React, { useEffect, useState } from 'react'
import { Modal, Button } from 'react-bootstrap';
import PlaceHolderImg from '../../assets/images/chat-placeholder.png';
import { Scrollbars } from 'react-custom-scrollbars-2';
import './FollowingModal.scss'
import { useSelector } from 'react-redux';
import useHeader from '../../Helper/useHeader';
import * as cs from '../../Api/cs'
import UserImg from '../../assets/images/default.png'
import { useNavigate } from 'react-router-dom';

const FollowingModal = ({ followingModal, setFollowingModal, artistId, getFollowingFollowers }) => {
  const { userId } = useSelector(state => state.user)

  const headers = useHeader()
  const navigate = useNavigate()

  const [following, setFollowing] = useState([])
  const [status, setStatus] = useState({})

  useEffect(() => {
    getFollowingData()
  }, [])


  const getFollowingData = async () => {
    const response = await cs.get('getAllFollowing/' + artistId + '/' + 'active', 'follow', {}, headers)
    setFollowing(response?.data?.data)

    response?.data?.data?.forEach((item) => {
      setStatus((prevState) => ({
        ...prevState,
        [item?._id]: true
      }));
    });
  }

  const addFollower = async (id) => {
    const _data = {
      "followUser": id
    }
    const response = await cs.post('add', 'follow', _data, headers)
    if (response?.data?.data?.status) {
      setStatus((prevState) => ({
        ...prevState,
        [id]: response?.data?.data?.status === 'inactive' ? false : true
      }));
    }
    getFollowingFollowers()
  }

  return (
    <Modal size="md" show={followingModal} onHide={() => { setFollowingModal(false) }} className='following-modal' aria-labelledby="contained-modal-title-vcenter"
      centered>
      <Modal.Header closeButton>
        <Modal.Title className='text-start'>Following</Modal.Title>
      </Modal.Header>
      <Modal.Body>

        <Scrollbars style={{ width: '100%', height: '320px' }} disableHorizontalScrolling>
          <div className='following-wrapper'>
            {following && following?.length > 0 ?
              following.map((item) => (
                <div className='following-row' key={item?._id}>
                  <div className='following-img active cursor-pointer' onClick={() => { navigate(`/profile/${item?._id}`, { state: { type: "user", id: item?._id } }); setFollowingModal(false) }}><img src={item?.image ? item?.image : UserImg} alt='Avatar' style={{ width: 48, height: 48}} /></div>
                  <div className='following-name cursor-pointer' onClick={() => { navigate(`/profile/${item?._id}`, { state: { type: "user", id: item?._id } }); setFollowingModal(false) }}>{item?.name ? item?.name : item?.userName}</div>
                  {artistId === userId &&
                    <div className='btn-wrap'><Button className='active' size='sm' variant='secondary' onClick={() => { addFollower(item?._id) }}>   {status[item?._id] ? 'Following' : "I'm a Fan"} </Button></div>
                    //  <div className='btn-wrap'><Button size='sm' variant='secondary'>Following</Button></div>
                  }
                </div>
              ))
              :
              <div style={{ color: "white" }}>Not following anyone yet.</div>}



          </div>
        </Scrollbars>

      </Modal.Body>

    </Modal>
  )
}

export default FollowingModal
