import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Player from '../../Component/Player/Player';

function PrivateLayout() {


    const { isLoggedIn } = useSelector(state => state?.user);
    
    const {showPlayer} = useSelector(state => state.player)

  
    if (isLoggedIn) {
        return (
        <>
        <Outlet />
        </>
        )
    }

    return <Navigate to="/" />
}

export default PrivateLayout