import React, { useEffect, useState } from 'react'
import BattleCard from '../BattelCard/BattleCard'
import { Button } from 'react-bootstrap'
import './battles.scss'
import { ViewAllIcon } from '../Icons'
import useHeader from '../../Helper/useHeader'
import { useDispatch, useSelector } from 'react-redux'
import * as cs from '../../Api/cs'
import { useNavigate } from 'react-router-dom'
import { updateBattleId } from '../../Redux/Slices/battle.slice'
import StartBattleModal from '../../Modal/StartBattleModal/StartBattleModal'
import OpenBattleModal from '../../Modal/OpenBattleModal/OpenBattleModal'
import ReportModal from '../../Modal/ReportModal/ReportModal'
import Login from '../../Pages/Login/Login'
import SignUp from '../../Pages/SignUp/SignUp'
import OtpModal from '../../Modal/OtpModal'
import EmailLinkForPassword from '../../Pages/EmailLinkForPassword/EmailLinkForPassword'
import SetUpAccount from '../../Modal/SetUpAccount/SetUpAccount'

const JoinBattles = ({ genre = null }) => {
    const [showModal, setShowModal] = useState(false);
    const [showOpenBattleModal, setShowOpenBattleModal] = useState(false);
    const [trackInfo, setTrackInfo] = useState('');
    const [battleOption, setBattleOption] = useState('')
    const [battleList, setBattleList] = useState([])
    const [addToPlaylist, setAddToPlaylist] = useState(false);
    const [sort, setSort] = useState('dsc')
    const [filter, setFilter] = useState('abcd')
    const headers = useHeader()
    const [awaitingBattle, setAwaitingBattle] = useState([])
    const [showLogin, setShowLogin] = useState(false);
    const [showOtpModal, setShowOtpModel] = useState(false)
    const [emailLinkModal, setEmailLinkModal] = useState(false)
    const [showSignUp, setShowSignUp] = useState(false)
    const [showSetUp, setShowSetUp] = useState(false)
    const [user, setUser] = useState(null)
    const [id, setId] = useState(null)
    const [token2, setToken2] = useState(null)

    // const socket = useContext(SocketContext)

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { userId } = useSelector(state => state.user)

    const handleOpenBattleClose = () => {
        setShowOpenBattleModal(false);
    };

    const handleShow = () => setShowModal(true);
    const handleClose = () => setShowModal(false);
    const handleLoginClose = () => {
        setShowLogin(false)
    }
    const handleOtpClose = () => {
        setShowOtpModel(false)
    }
    const handleSignUpClose = () => {
        setShowSignUp(false)
    }

    const handleSetUpClose = () => {
        setShowSetUp(false)
    }
    const handleEmailLinkClose = () => {
        setEmailLinkModal(false)
    }



    useEffect(() => {
        getBattleList()
        if (genre == null) {
            getAwaitingBattle()
        }
        else if (genre != null) {
            getAwaitingBattleOfGenre()
        }
    }, [])

    const getBattleList = async () => {
        const response = await cs.get('list/' + sort + "/" + filter, 'battel', {}, headers)
        setBattleList(response?.data?.data)

    }

    const getAwaitingBattle = async () => {
        const response = await cs.get('still/notjoin', 'battel', null, headers)
        setAwaitingBattle(response?.data?.data.slice(0, 10))
    }

    const getAwaitingBattleOfGenre = async () => {
        const response = await cs.get('awaitingBattlesOfGenre/' + genre, 'battel', null, headers)
        setAwaitingBattle(response?.data?.data.slice(0, 10))
    }

    const handleBattleCard = (item) => {
        dispatch(updateBattleId(item))
        navigate(`/battle/${item}`, { state: { itemData: item, battles: awaitingBattle } })
    }

    const handleCreateBattle = async () => {
        if (userId != null) {
            handleShow()
        }
        else if (userId == null) {
            setShowLogin(true)
        }
    }


    const usersAwaitingBattle = userId ? awaitingBattle?.filter(item => {
        return item.battlecreatedBy._id !== userId
    }) || [] : awaitingBattle;
    return (
        <>
            {awaitingBattle && awaitingBattle.length > 0 &&
                <div className='battles-container join-battles'>
                    <div className='battles-title-wrapper'>
                        <div className='battles-title'>Join A Battle</div>
                        <div className='btn-wrap' ><Button variant='secondary' onClick={() => {
                            navigate(`/genre/${genre ? genre : 'all'}/join-battle?type=battle`, {
                                state: {
                                    type: "battle",
                                    title: "Join A Battle",
                                    ...(genre !== null && { genre })
                                }
                            });
                        }}>View all</Button>
                            <Button variant='primary' type='button' onClick={handleCreateBattle}>Create Battle</Button></div>
                    </div>
                    <div className='battles-content-wrap'>

                        {usersAwaitingBattle.length > 0 ?
                            usersAwaitingBattle.map((item) =>
                            (
                                <div onClick={() => { handleBattleCard(item?._id) }}>
                                    <BattleCard battleId={item?._id} type={item?.battleOpponentStatus ? null : 'awaiting'} />
                                </div>

                            )) :
                            <div style={{ color: "white" }}>No Battles to Join</div>
                        }
                    </div>
                    <div className='viewall-btn-wrap' onClick={() => {
                        navigate(`/genre/${genre ? genre : 'all'}/join-battle`, {
                            state: {
                                type: "battle",
                                title: "Join A Battle",
                                ...(genre !== null && { genre })
                            }
                        });
                    }}><Button variant='secondary'><ViewAllIcon /> View all</Button></div>
                </div>
            }
            {showModal && <StartBattleModal showModal={showModal} handleClose={handleClose} setShowModal={setShowModal} setShowOpenBattleModal={setShowOpenBattleModal} setTrackInfo={setTrackInfo} setBattleOption={setBattleOption} setAddToPlaylist={setAddToPlaylist} addToPlaylist={addToPlaylist} />}
            {showOpenBattleModal && <OpenBattleModal showOpenBattleModal={showOpenBattleModal} trackInfo={trackInfo} setShowModal={setShowModal} setShowOpenBattleModal={setShowOpenBattleModal} battleOption={battleOption} getBattleList={getBattleList} handleOpenBattleClose={handleOpenBattleClose} addToPlaylist={addToPlaylist} />}
            {showLogin && <Login showLogin={showLogin} handleLoginClose={handleLoginClose} setShowLogin={setShowLogin} setShowModel={setShowOtpModel} setEmailLinkModal={setEmailLinkModal} setShowSignUp={setShowSignUp} setUser={setUser} setShowSetUp={setShowSetUp} setId={setId} setToken2={setToken2} />}
            {showSignUp && <SignUp showSignUp={showSignUp} handleSignUpClose={handleSignUpClose} setShowSignUp={setShowSignUp} setShowLogin={setShowLogin} setShowModel={setShowOtpModel} setUser={setUser} user={user} setId={setId} setToken2={setToken2} />}
            {showOtpModal && <OtpModal showModal={showOtpModal} handleClose={handleOtpClose} setShowModel={setShowOtpModel} user={user} setShowLogin={setShowLogin} setShowSetUp={setShowSetUp} id={id} token2={token2} />}
            {emailLinkModal && <EmailLinkForPassword emailLinkModal={emailLinkModal} handleEmailLinkClose={handleEmailLinkClose} setShowLogin={setShowLogin} />}
            {showSetUp && <SetUpAccount showSetUp={showSetUp} handleSetUpClose={handleSetUpClose} />}
        </>
    )
}

export default JoinBattles
