import { combineReducers, configureStore } from '@reduxjs/toolkit'
import userReducer from "./Slices/user.slice";
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import battleReducer from './Slices/battle.slice'
import searchReducer from './Slices/search.slice'
import playerReducer from './Slices/player.slice'
import sidebarReducer from './Slices/sidebar.slice'
import chatSlice from './Slices/chat.slice';
import badgeSlice from './Slices/badge.slice';
import modalReducer  from './Slices/modal.slice';

const persistConfig = {
  key: 'root',
  storage,
}




const reducers = (state, action) => {
  if (action.type === 'LOGOUT') {
    // Reset all slices to initial state
    state = undefined;
  }

  return combineReducers({
    user: userReducer,
    battle: battleReducer,
    search: searchReducer,
    player: playerReducer,
    sidebar: sidebarReducer,
    chat: chatSlice,
    badge : badgeSlice,
    modal: modalReducer,
  })(state, action);
};


const persistedReducer = persistReducer(persistConfig, reducers)

export const store = configureStore({
  reducer: persistedReducer,
})