"use client"

import React, { useContext, useEffect, useState, useRef } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import moment from 'moment';
import Dropdown from 'react-bootstrap/Dropdown';
import Logo from '../../assets/images/beta_logo.png'

import UserImg from '../../../src/assets/images/default.png';
// import FireIcon from '../../../src/assets/images/icons/fire-icon.png'
import { Button, Form, InputGroup } from 'react-bootstrap';
import { SearchIcon, ChatIcon, NotificationIcon, CartIcon, CloutIcon } from '../Icons';
import { useSelector, useDispatch } from 'react-redux'
import { updateLoginStatus, updateUserData } from '../../Redux/Slices/user.slice';
// import { updateLoginStatus } from '../../Redux/Slices/user.slice';
import { updateChatUserId, updateShowChat, updateUnreadMessage } from '../../Redux/Slices/chat.slice';
import { updateBlinkSearch, updateSearch, updateSearchStatus } from '../../Redux/Slices/search.slice';
import { SocketContext } from '../../Context/socket';
import './Header.scss';
// import { bucketUrl } from '../../Helper/Constant';
import * as cs from '../../Api/cs'
import useHeader from '../../Helper/useHeader';
import { updateBattleId } from '../../Redux/Slices/battle.slice';
import Login from '../../Pages/Login/Login';
import OtpModal from '../../Modal/OtpModal';
import EmailLinkForPassword from '../../Pages/EmailLinkForPassword/EmailLinkForPassword';
import SignUp from '../../Pages/SignUp/SignUp';
import SetUpAccount from '../../Modal/SetUpAccount/SetUpAccount';
import { Scrollbars } from 'react-custom-scrollbars-2';
import Chat from '../Chat/Chat';
import BottomBar from '../BottomBar/BottomBar';
import Wallet from '../Wallet/Wallet';
import Activity from '../Activity/Activity';
import Badges from '../Badges/Badges';
import { updateActivityShow, updateWalletShow } from '../../Redux/Slices/modal.slice';
import { updateBadgeId, updateIsBadgeInfo } from '../../Redux/Slices/badge.slice';

const Header = () => {

  const [showLogin, setShowLogin] = useState(false);
  const navigate = useNavigate();
  const headers = useHeader()
  const socket = useContext(SocketContext)
  

  const { searchBy, search } = useSelector(state => state.search)
  const { isLoggedIn, userId } = useSelector(state => state.user);

  const dispatch = useDispatch();
  const [search1, setSearch1] = useState(search)
  const [notification, setNotification] = useState([])
  const [battleList, setBattleList] = useState([])
  const [sort, setSort] = useState('dsc')
  const [filter, setFilter] = useState('abcd')
  const [showModal, setShowModel] = useState(false)
  const [emailLinkModal, setEmailLinkModal] = useState(false)
  const [showSignUp, setShowSignUp] = useState(false)
  const [showSetUp, setShowSetUp] = useState(false)
  const [user, setUser] = useState(null)
  const [id, setId] = useState(null)
  const [token2, setToken2] = useState(null)
  const [earlierNotification, setEarlierNotification] = useState([])
  const [showChat, setShowChat] = useState(false)
  const [userData, setUserData] = useState([])
  const [userDetails, setUserDetails] = useState()
  const [showNotificationDropdown, setShowNotificationDropdown] = useState(false);
  const [mobileSearch, setMobileSearch] = useState(false);
  const [showProfileDropdown, setShowProfileDropdown] = useState(false);
  const [newNotificationCount, setNewNotificationCount] = useState(0);
  const [badgeShow, setBadgeShow] = useState(false)
  const [userData1, setUserData1] = useState({})
  const { searchStatus, blinkSearch } = useSelector(state => state?.search)
  const { showChatMessage, unreadMessage } = useSelector(state => state.chat)
  const [isWalletVisible, setIsWalletVisible] = useState(false);

  const { userInfo } = useSelector(state => state.user)

  const { walletShow, activityShow } = useSelector(state => state.modal)

  const searchRef = useRef(null)

  const handleLogout = () => {

    localStorage.removeItem("token")
    localStorage.clear()
    dispatch({ type: 'LOGOUT' });;
    handleLiveStatus(userId)
    if (socket) {
      socket.disconnect();
    }

    navigate('/')
    window.location.reload(); // Reload the page after navigation
  }
  //Handle Live Status ...
  const handleLiveStatus = (userId) => {
    let data = {
      id: userId,
      status: false,
    }

    socket.emit('live', data);
  }

  useEffect(() => {
    if (showChatMessage)
      setShowChat(true)
  }, [showChatMessage])

  const handleClose = () => {
    setShowModel(false)
  }

  const handleEmailLinkClose = () => {
    setEmailLinkModal(false)
  }

  useEffect(() => {
    getBattleList()
    // fetchRooms()
    getEarlierNotification()
    // getUserData()
    getUsersList()
    if (userId) {
      getUserData()
    }
  }, [])

  useEffect(() => {
    if (userId) {
      fetchRooms()
    }
  }, [userId])


  useEffect(() => {
    setNewNotificationCount(notification?.length); // Update count when new notifications arrive
  }, [notification]);

  useEffect(() => {
    if (showNotificationDropdown) {
      changeNotificationStatus()
      // setNewNotificationCount(0)
    }
  }, [showNotificationDropdown]);

  useEffect(() => {
    if (userInfo && userInfo?.is_active && (!userInfo?.userName)) {
      setShowSetUp(true);
    }
  }, [userInfo]);


  const changeNotificationStatus = async () => {
    const response = await cs.put('changeNotificationStatus', 'notification', null, null, headers)

  }

  useEffect(() => {

    setSearch1(search)
    if (blinkSearch && searchRef.current) {
      searchRef.current.focus();
      dispatch(updateBlinkSearch(false))
    }
  }, [search, blinkSearch])


  useEffect(() => {
    getUsersList()
    fetchRooms()
  }, [socket])

  // useEffect(() => {
  //   if (userId) {
  //     getUserData()
  //   }
  // }, [])



  // useEffect(() => {
  //   if (blinkSearch && searchRef.current) {
  //     searchRef.current.focus();
  //     dispatch(updateBlinkSearch(false))
  //   }
  // }, [blinkSearch]);



  const getUserData = async () => {
    const response = await cs.get("getUserList/" + userId, 'users', {}, headers)
    setUserData1(response?.data?.data)
    dispatch(updateUserData(response?.data?.data))
  }


  const handleSearch = (e) => {
    setSearch1(e.target.value)
    dispatch(updateSearch(e.target.value))
    dispatch(updateSearchStatus("false"))
  }

  const handleSearchData = () => {
    if (searchStatus == 'false') {
      dispatch(updateSearchStatus("true"))
    }
    if (search.trim() != '') {
      navigate('/search')
    }

  }

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' || e.keyCode === 66 || e.keyCode === 13 || e.charCode === 66) {
      handleSearchData();

    }
  };

  useEffect(() => {
    socket.on("challengeNotification", (data) => {
      setNotification((prevNotification) => [...(prevNotification || []), data]);

    })

    socket.on('challengeAcceptedNotification', (data) => {
      setNotification((prevNotification) => [...(prevNotification || []), data]);
    })


    socket.on("newFanNotification", (data) => {
      setNotification((prevNotification) => [...(prevNotification || []), data]);
    });


    socket.on('commentAdded', (data) => {
      setNotification((prevNotification) => [...(prevNotification || []), data]);
    })

    socket.on('battleEnded', (data) => {
      setNotification((prevNotification) => [...(prevNotification || []), data]);
    })

    socket.on('badgeUnlocked', (data) => {
      setNotification((prevNotification) => [...(prevNotification || []), data]);
    })

  }, [socket]);

  useEffect(() => {
    if (!showNotificationDropdown)
      setNewNotificationCount(0)
  }, [showNotificationDropdown])

  const getBattleList = async () => {
    const response = await cs.get('list/' + sort + "/" + filter, 'battel', {}, headers)
    setBattleList(response?.data?.data)
  }

  const handleBattleCard = (item) => {
    setShowNotificationDropdown(false)
    dispatch(updateBattleId(item))
    navigate(`/battle/${item}`, { state: { itemData: item, battles: battleList } })
  }

  const handleLoginShow = () => {
    setShowLogin(true)
  }

  const handleSignUpShow = () => {
    setShowSignUp(true)
  }



  const handleLoginClose = () => {
    setShowLogin(false)
  }


  const handleSignUpClose = () => {
    setShowSignUp(false)
  }

  const handleSetUpClose = () => {
    setShowSetUp(false)
  }

  const handleWallet = () => {
    setShowChat(false)
    setShowNotificationDropdown(false)
    setShowProfileDropdown(false)
    dispatch(updateActivityShow(false))
    setBadgeShow(false)
    dispatch(updateWalletShow(true))
  }

  const handleMobileWallet = () => {
    const newWalletState = !isWalletVisible;
    setIsWalletVisible(newWalletState);
    setShowChat(false)
    setShowNotificationDropdown(false)
    setShowProfileDropdown(false)
    dispatch(updateActivityShow(false))
    setBadgeShow(false)
    dispatch(updateWalletShow(newWalletState));
    document.body.classList.remove('hidden');


  }

  const handleActivity = () => {
    setShowChat(false)
    setShowNotificationDropdown(false)
    setShowProfileDropdown(false)
    dispatch(updateWalletShow(false))
    setBadgeShow(false)
    dispatch(updateActivityShow(true))
  }


  /**
   * Get User List Function ...
   */
  const getUsersList = async () => {

    try {
      const response = await cs.get('listing', 'users', {}, headers);
      if (response?.data?.code === 200) {
        setUserData(response?.data?.data)
      }
    }
    catch (error) {

    }

  }



  const getEarlierNotification = async () => {
    try {

      const response = await cs.get('list', 'notification', null, headers)
      setEarlierNotification(response?.data?.data?.earlierNotifications)
      setNotification(response?.data?.data?.newNotifications)
    }
    catch (error) {

    }
    finally {
      setNewNotificationCount(0)
    }
  }


  const handleChat = () => {
    setShowChat(!showChat)
    dispatch(updateShowChat(!showChat))
    dispatch(updateChatUserId(null))
    setShowNotificationDropdown(false)
    if (walletShow) {
      dispatch(updateWalletShow(false))
    }
    if (activityShow) {
      dispatch(updateActivityShow(false))
    }
    if (badgeShow) {
      setBadgeShow(false)
    }
  }

  const handleNotificationToggle = () => {
    if (showChat) {
      setShowChat(false)
      dispatch(updateShowChat(false))
    }
    if (!showNotificationDropdown) {
      setShowNotificationDropdown(true);
      getEarlierNotification()
    }
    else {
      setShowNotificationDropdown(false);
    }

    dispatch(updateWalletShow(false))
    dispatch(updateActivityShow(false))
    if (badgeShow) {
      setBadgeShow(false)
    }
  };

  useEffect(() => {
    if (!showNotificationDropdown)
      setNewNotificationCount(0)
  }, [showNotificationDropdown])

  const handleProfileClick = () => {
    if (showChat) {
      setShowChat(false)
      dispatch(updateShowChat(false))
    }
    setShowNotificationDropdown(false)
    if (walletShow) {
      dispatch(updateWalletShow(false))
    }
    if (activityShow) {
      dispatch(updateActivityShow(false))
    }
    if (badgeShow) {
      setBadgeShow(false)
    }
    setShowProfileDropdown(!showProfileDropdown)
  }

  const formatClout = (clout) => {
    if (clout >= 1000000) {
      return `${(clout / 1000000).toFixed(1)}m`;
    } else if (clout >= 1000) {
      return `${(clout / 1000).toFixed(1)}k`;
    } else {
      return clout?.toString();
    }
  };

  const handleNotificationButton = (data) => {
    if (data?.typeNotification === 'User') {
      navigate(`/profile/${data?.senderId?._id}`)
    }
    else if (data.typeNotification === 'Track') {

    }
    else if (data?.typeNotification === 'Battle') {
      handleBattleCard(data?.battleId?._id)
    }
    else if (data?.typeNotification === 'Badge') {
      setShowNotificationDropdown(false);
      dispatch(updateIsBadgeInfo(true))
      dispatch(updateBadgeId(data?.badgeId._id))
      setBadgeShow(true)

    }
  }

  const handleFaqSupport = () => {
    navigate(`/faq`)
  };

  const handleContact = () => {
    navigate(`/contact`);
  };



  const fetchRooms = async () => {
    try {
      const response = await cs.get(`roomList/${userId}`, 'chatMessage', {}, headers);
      if (response?.data?.code == 200) {
        let readAll = 0
        for (const item of response?.data?.data || []) {
          if (item?.unreadMessageCount > 0 && userId !== item?.lastMessageBy) {
            dispatch(updateUnreadMessage(true));
            readAll = 1
            break;

          }
        }
        if (readAll == 0) {

          dispatch(updateUnreadMessage(false));
        }
      }
    }
    catch (error) {

    }
  }
  const extractLevelFromMessage = (message) => {
    const match = message.match(/level (\d+)/);
    return match ? parseInt(match[1], 10) : 0; // default to level 0 if not found
  };
  const getLevelImage = (item) => {
    const level = extractLevelFromMessage(item.message);
    return item?.badgeId?.levels?.[level - 1]?.levelImage || item?.badgeId?.badgeImage;
  };

  const [countdown, setCountdown] = useState({});
  const [newCountdown, setNewCountdown] = useState({});
  //notification time
  const getRemainingTime = (createdAt) => {
    const createdTime = new Date(createdAt).getTime();
    const currentTime = new Date().getTime();
    const timeDifference = createdTime + 24 * 60 * 60 * 1000 - currentTime;

    if (timeDifference <= 0) {
      return 'Expired';
    }

    const hours = Math.floor(timeDifference / (1000 * 60 * 60)).toString().padStart(2, '0');
    const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60)).toString().padStart(2, '0');
    const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000).toString().padStart(2, '0');

    return `${hours} : ${minutes} : ${seconds}`;
  };

  useEffect(() => {
    const updateCountdowns = () => {
      const newCountdown = {};
      earlierNotification?.forEach((item) => {
        if (item.typeNotification === "Battle" && item?.battleId?.opponentcreatedBy === null) {
          newCountdown[item._id] = getRemainingTime(item.createdAt);
        }
      });
      setCountdown(newCountdown);
    };

    // Initial update
    updateCountdowns();

    // Update countdown every second
    const interval = setInterval(() => {
      updateCountdowns();
    }, 1000);

    // Clear interval on component unmount
    return () => clearInterval(interval);
  }, [earlierNotification]);

  // THIS IS FOR NEW NOTIFICATION 
  useEffect(() => {
    const updateNewCountdowns = () => {
      const newCountdown = {};
      notification?.forEach((item) => {
        if (item.typeNotification === "Battle" && item?.battleId?.opponentcreatedBy === null) {
          newCountdown[item._id] = getRemainingTime(item.createdAt);
        }
      });
      setNewCountdown(newCountdown);
    };

    updateNewCountdowns();

    const interval = setInterval(() => {
      updateNewCountdowns();
    }, 1000);

    return () => clearInterval(interval);
  }, [notification]);


  const handleNavigationAndRefresh = () => {
    navigate('/');
    dispatch(updateWalletShow(false))
    document.body.classList.remove('hidden');
    // This will refresh the page
  };


  // console.log('notification', notification)
  // console.log('notification earlierNotification', earlierNotification)


  return (
    <>
      {((showChat && userId) || showChatMessage) &&
        <Chat
          userData={userData}
          userId={userId}
          userDetails={userDetails}
          showChat={showChat}
          handleLiveStatus={handleLiveStatus}
          setShowChat={setShowChat}
        />}

      {/* Mobile Search  */}
      {mobileSearch ? <>
        <div className='header-search-wrap-m'>
          <InputGroup>
            <Form.Control
              placeholder="Search..."
              name="search"
              value={search1}
              onChange={handleSearch}
              onKeyDown={handleKeyPress}
            />
            <InputGroup.Text  >
              <div className='icon-wrap' onClick={handleSearchData}><SearchIcon /></div>

            </InputGroup.Text>
          </InputGroup>
        </div></> : ''}
      <header>
        <div className='main-wrapper'>
          <div className='main-container'>
            <div className='header-wrapper'>
              <div className='header-search-wrap'>
                <InputGroup>
                  <Form.Control
                    placeholder="Search..."
                    name="search"
                    ref={searchRef}
                    value={search1}
                    onChange={handleSearch}
                    onKeyDown={handleKeyPress}
                  />
                  <InputGroup.Text  >
                    <div className='icon-wrap desktop' onClick={handleSearchData}><SearchIcon /></div>
                    <div className='icon-wrap mobile' onClick={() => { setMobileSearch(!mobileSearch); document.body.classList.remove('hidden'); }}><SearchIcon /></div>
                  </InputGroup.Text>
                </InputGroup>
              </div>
              <div className='logo cursor-pointer' onClick={handleNavigationAndRefresh}><img src={Logo} alt='CNDTR' /></div>
              <div className='header-auth-wrap'>
                {isLoggedIn ? (

                  <>
                    <div className='header-log-content'>
                      <div className="header-icons-wrap">
                        <div className={`header-icons chat   ${showChat || showChatMessage ? 'active' : ''}`} onClick={handleChat}>
                          <div className={`${unreadMessage ? 'notification-count-wrap' : ''}`}></div>
                          <ChatIcon />
                        </div>
                        <div className={`header-icons notification ${showNotificationDropdown ? 'active' : ''}`} >

                          {newNotificationCount > 0 && <div className='notification-count-wrap'>
                            {/* {newNotificationCount} */}
                          </div>}
                          {/* {notification.length  > 0 && <div className='notification-count-wrap'>{notification.length}</div>} */}
                          <Dropdown show={showNotificationDropdown} onToggle={handleNotificationToggle}>
                            <Dropdown.Toggle variant="" id="notification" onClick={handleNotificationToggle} >
                              <NotificationIcon />
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              <Scrollbars style={{ width: '100%', height: '300px' }}>
                                <div className='notification-container'>
                                  {notification?.length > 0 && <div className='notification-title'>New</div>}
                                  {notification?.length > 0 && notification.map((item) => (
                                    <div className='notification-wrapper new' key={item?._id}>
                                      <div className='notification-content-wrap' onClick={() => { handleNotificationButton(item) }}>
                                        <div className='notification-detail-wrap'>
                                          <div className='notification-user-img'>
                                            {item.typeNotification === "Badge" ? (
                                              <img src={getLevelImage(item)} alt="Badge" />
                                            ) : (
                                              <img src={item?.senderId?.image ? item?.senderId?.image : item?.receiverId?.image} alt="User Image" />
                                            )}
                                          </div>
                                          <div className='notification-detail'>
                                            {item.typeNotification === "Battle" && item?.battleId?.opponentcreatedBy === null && (
                                              <div className='newcountdown'>
                                                <div className='title'>
                                                  Challenge Received
                                                </div>
                                                <div className='message'>
                                                  <span>Invitation expires in :   {newCountdown[item._id] || 'Calculating...'}</span>
                                                </div>
                                              </div>
                                            )}
                                            {item.message}
                                          </div>
                                        </div>
                                        <div style={{ display: 'flex', justifyContent: 'flex-end', fontSize: 12, color: 'white' }}>
                                          <span>  {moment(item.createdAt).fromNow()}</span>
                                        </div>

                                      </div>
                                    </div>
                                  ))}
                                  {earlierNotification && earlierNotification.length > 0 && <div className='notification-title'>Earlier</div>}
                                  {earlierNotification && earlierNotification.length > 0 && earlierNotification.map((item) => (
                                    <div className='notification-wrapper' key={item.id}>
                                      <div className='notification-content-wrap' onClick={() => { handleNotificationButton(item) }}>
                                        <div className='notification-detail-wrap'>
                                          <div className='notification-user-img'>
                                            {item.typeNotification === "Badge" ? (
                                              <img src={getLevelImage(item)} alt="Badge" />
                                            ) : (
                                              <img src={item?.senderId?.image ? item?.senderId?.image : item?.receiverId?.image} alt="User Image" />
                                            )}
                                          </div>
                                          <div className='notification-detail'>
                                            {item.typeNotification === "Battle" && item?.battleId?.opponentcreatedBy === null && (
                                              <div className='countdown'>

                                                {countdown[item._id] && !countdown[item._id].includes('Expired') ? (
                                                  <>
                                                    <div className='title'>
                                                      Challenge Received
                                                    </div>
                                                    <div className='message'>
                                                      <span>Invitation expires in : {countdown[item._id]}</span>
                                                    </div>
                                                  </>
                                                ) : (
                                                  <div className='Expired'>
                                                    Challenge has Expired
                                                  </div>
                                                )}
                                              </div>


                                            )}
                                            {item.message}
                                          </div>
                                        </div>
                                        <div style={{ display: 'flex', justifyContent: 'flex-end', fontSize: 12, color: 'white' }}>
                                          <span>  {moment(item.createdAt).fromNow()}</span>
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </Scrollbars>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                        {/* <div className="header-icons"><CartIcon /></div> */}
                      </div>
                      <div className="header-user-wrap">
                        <div className="user-balance-mobile" onClick={handleMobileWallet}>
                          <div className="balance-icon">
                            <CloutIcon />
                          </div>
                          <span>{formatClout(userInfo?.clout)}</span>
                        </div>

                        <Dropdown show={showProfileDropdown} onToggle={handleProfileClick} onClick={() => handleProfileClick()}>
                          <Dropdown.Toggle variant="" id="user" >
                            <div className="user-img-wrap">
                              <img src={userInfo?.image ? userInfo?.image : UserImg} />
                            </div>
                            <div className="header-user-detail">
                              <div className="user-name">{userInfo?.name ? userInfo?.name : userInfo?.userName}</div>
                              <div className="user-balance"><div className="balance-icon">
                                <CloutIcon />
                              </div> <span>{formatClout(userInfo?.clout)}</span></div>
                            </div>

                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <div className='dropdown-item' onClick={handleWallet}>
                              <div className='dropdown-title'>Wallet</div>

                            </div>
                            <div className='dropdown-item'>
                              <div className='dropdown-title' onClick={() => {
                                navigate(`/private/profile/${userId}`, { state: { type: "user", id: userId } })
                              }}>My Profile</div>
                            </div>
                            <div className='dropdown-item' onClick={() => { navigate('/private/edit-profile') }}>
                              <div className='dropdown-title'>Settings</div>
                            </div>
                            <div className='dropdown-item' onClick={handleContact}>
                              <div className='dropdown-title'>Contact</div>
                            </div>
                            <div className='dropdown-item' onClick={handleFaqSupport}>
                              <div className='dropdown-title'>Support</div>
                            </div>
                            <div className='dropdown-item'>
                              <div className='dropdown-title' onClick={handleLogout}>Log Out</div>
                            </div>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>

                    </div>
                  </>
                ) : (
                  <>
                    <div className='header-btn-wrap'>
                      <Button variant='light' onClick={() => handleLoginShow()}>Login</Button>
                      <Button variant='primary' onClick={() => handleSignUpShow()} >Sign Up</Button>
                      <Button variant='primary' onClick={() => handleLoginShow()}>Sign In</Button>
                    </div>
                  </>
                )}
                {showLogin && <Login showLogin={showLogin} handleLoginClose={handleLoginClose} setShowLogin={setShowLogin} setShowModel={setShowModel} setEmailLinkModal={setEmailLinkModal} setShowSignUp={setShowSignUp} setUser={setUser} setShowSetUp={setShowSetUp} setId={setId} setToken2={setToken2} />}
                {showSignUp && <SignUp showSignUp={showSignUp} handleSignUpClose={handleSignUpClose} setShowSignUp={setShowSignUp} setShowLogin={setShowLogin} setShowModel={setShowModel} setUser={setUser} user={user} setId={setId} setToken2={setToken2} setShowSetUp={setShowSetUp} />}
                {showModal && <OtpModal showModal={showModal} handleClose={handleClose} setShowModel={setShowModel} user={user} setShowLogin={setShowLogin} setShowSetUp={setShowSetUp} id={id} token2={token2} />}
                {emailLinkModal && <EmailLinkForPassword emailLinkModal={emailLinkModal} handleEmailLinkClose={handleEmailLinkClose} setShowLogin={setShowLogin} />}
                {showSetUp && <SetUpAccount showSetUp={showSetUp} handleSetUpClose={handleSetUpClose} />}
                {walletShow && <Wallet setBadgeShow={setBadgeShow} clout={formatClout(userInfo?.clout)} />}
                {activityShow && <Activity />}
                {badgeShow && <Badges setBadgeShow={setBadgeShow} />}

              </div>
            </div>
          </div>
        </div>
      </header>
      <BottomBar />
      {/* <Wallet/> */}
      {/* <Activity/> */}
      {/* <Badges/> */}
    </>
  )
}

export default Header
