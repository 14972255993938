import React, { useContext, useEffect, useState } from 'react'
import { Modal, Button } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import { useDropzone } from 'react-dropzone';
import { yupResolver } from '@hookform/resolvers/yup';
import { trackSchemaToJoinBattle } from '../ValidationSchema/validationSchema'
import useHeader from '../Helper/useHeader';
import * as cs from '../Api/cs'
import { useDispatch, useSelector } from 'react-redux';
import Form from 'react-bootstrap/Form';
import { CloseIcon, DeleteIcon, ImageIcon, TrackIcon } from '../Component/Icons';
import { SocketContext } from '../Context/socket';
import { BallTriangle } from 'react-loader-spinner';
import { updateUserData } from '../Redux/Slices/user.slice';

const TrackToJoinBattle = ({ showModal, handleClose, setBattleOpponentStatus, genre = null }) => {
    const headers = useHeader()

    const [trackList, setTrackList] = useState([]);
    const [genreList, setGenreList] = useState([]);
    const [battleData, setBattleData] = useState([])
    const [image, setImage] = useState(null)
    const [selectedFile, setSelectedFile] = useState(null);
    const [addToPlaylist, setAddToPlaylist] = useState(false);
    const [loading, setLoading] = useState(false);
    const [battleGenre, setBattleGenre] = useState('');
    const [trackDuration, setTrackDuration] = useState(0)




    const socket = useContext(SocketContext)
    const dispatch = useDispatch()

    const { battleId } = useSelector(state => state.battle)

    const { control, handleSubmit, setValue, formState: { errors }, register, watch, reset, clearErrors } = useForm({
        resolver: yupResolver(trackSchemaToJoinBattle),
        mode: 'onChange',
    });;

    const handleImageSelect = (acceptedFiles) => {
        setValue('imageInput', acceptedFiles);
        setImage(URL.createObjectURL(acceptedFiles[0]));
        clearErrors('imageInput');
      };
    
      const handleAudioSelect = (acceptedFiles) => {
        setValue('fileInput', acceptedFiles);
        setSelectedFile(acceptedFiles[0]);
        clearErrors('fileInput');
        getTrackDuration(acceptedFiles[0]);
      };
    

    const { getRootProps: getRootPropsForImage, getInputProps: getInputPropsForImage } = useDropzone({
        // accept: 'image/*',
        accept: {
            'image/png': ['.png'],
            'image/jpeg': ['.jpg', '.jpeg']
        },
        maxFiles: 1,
        multiple: false,
        onDrop: handleImageSelect
    });

    const { getRootProps: getRootPropsForAudio, getInputProps: getInputPropsForAudio } = useDropzone({
        accept: {
            'audio/mpeg': ['.mp3'],
        },
        maxFiles: 1,
        multiple: false,
        onDrop: handleAudioSelect,
    });


    useEffect(() => {
        getTrackListOfGenre()
        getGenreList()
        getBattleInfo()
        getTrackList()
    }, [])

    const { userId } = useSelector(state => state.user)


    const getBattleInfo = async () => {
        const response = await cs.get('getById/' + battleId, 'battel', {}, headers)
        setBattleData(response?.data?.data?.[0])
        setBattleGenre(response?.data?.data?.[0]?.battleTrackGenre?.[0])
        setValue('genreSelect', response?.data?.data?.[0]?.battleTrackGenre?.[0]?.label)

    }

    const getTrackList = async () => {
        const res = await cs.get('createdBy/' + userId, 'track', {}, headers)
        setTrackList(res?.data?.data)
    }

    const getTrackListOfGenre = async () => {
        const response = await cs.get('getUserTrackOfGenre/' + userId + '/' + genre?.[0]?._id, 'track', {}, headers)
        setTrackList(response?.data?.data)
    }

    const getGenreList = async () => {
        const res = await cs.get('list', 'genre', null, headers)
        setGenreList(res?.data?.data)
    }

    const getUserData = async () => {
        const response = await cs.get("getUserList/" + userId, 'users', {}, headers)
        dispatch(updateUserData(response?.data?.data))
    }
    const joinBattle = async (id) => {
        const _data = {
            "battleTrackOpponent": id,
            "opponentcreatedBy": userId,
            "battleOpponentStatus": "true"
        }

        const response = await cs.patch('oppJoin/' + battleId, 'battel', _data, {}, headers)
        if (response?.data?.code === 200) {
            setLoading(false)
            setBattleOpponentStatus(response?.data?.data?.battleOpponentStatus)
            const _data = {
                battleId: battleId,
                userId: userId,
                battleCreator: battleData?.battlecreatedBy
            }
            socket.emit("challenge_accepted", _data)
            getUserData()
        }
    }


    const getBattleDetailAfterOpponentJoin = async () => {
        const response = await cs.get('getById/' + battleId, 'battel', {}, headers)

    }


    const onSubmit = async (data) => {
        setLoading(true)
        console.log('onSubmit data', data)
        if (data?.genreSelect == null || data?.genreSelect == undefined) {
            setValue("genreSelect", battleGenre?.label)
        }


        let formObj = new FormData()
        let upload_data = {}
        let track_response;
        let selectedTrack
        if (data?.battleOption == 'addNewTrack') {


            const imgFormObj = new FormData()
            imgFormObj.append("data", data?.imageInput?.[0])


            const videoFormObj = new FormData()
            videoFormObj.append("data", data?.fileInput?.[0])

            const image = await cs.post('upload', 'util', imgFormObj, headers)

            const video = await cs.post('upload', 'util', videoFormObj, headers)

            formObj.append("track_image", image?.data?.data)
            formObj.append("track_video", video?.data?.data)
            formObj.append("track_name", data?.trackNameInput)
            formObj.append("track_genre", data?.genreSelect)
            formObj.append("status", "active")

            upload_data.track_image = image?.data?.data
            upload_data.track_video = video?.data?.data
            upload_data.track_name = data?.trackNameInput
            upload_data.track_genre = battleGenre?._id
            upload_data.status = 'active'
            upload_data.duration = trackDuration && trackDuration
            upload_data.track_description = data?.trackDescriptionInput
            // upload_data.track_tags = battleData?.tags
            if (addToPlaylist === true) {
                formObj.append("track_add", 1)
                upload_data.track_add = 1
            }




            track_response = await cs.post('add', 'track', upload_data, headers)



        }
        else if (data?.battleOption == 'chooseFromMyTrack') {

            selectedTrack = trackList?.find((track) => track?._id === data?.selectedTrack);


        }


        if (track_response) {
            joinBattle(track_response?.data?.data?._id)
        }
        else if (selectedTrack) {
            joinBattle(selectedTrack?._id)
        }
        handleClose()


    }

    useEffect(() => {
        if (!showModal) {
            reset();
            setImage(null);
            setSelectedFile(null);
            setValue("genreSelect", battleGenre?.label)
        }
    }, [showModal, reset]);


    // const handleImageDelete = () => {
    //     // Clear the image input value
    //     setValue('imageInput', null);
    //     // Clear the validation error for image input
    //     clearErrors('imageInput');
    //     // Clear the image preview
    //     setImage(null);
    // };

    // Inside the function where you delete the file
    // const handleFileDelete = () => {
    //     // Clear the file input value
    //     setValue('fileInput', null);
    //     // Clear the validation error for file input
    //     clearErrors('fileInput');
    //     // Clear the file name preview
    //     setSelectedFile(null);
    // };

    const getTrackDuration = (file) => {
        const audio = new Audio(URL.createObjectURL(file));
        audio.addEventListener('loadedmetadata', () => {
            const durationInSeconds = audio.duration;
            const minutes = Math.floor(durationInSeconds / 60);
            const seconds = Math.floor(durationInSeconds % 60);
            const formattedDuration = `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
            setTrackDuration(formattedDuration);

            setTrackDuration(formattedDuration);
        });
    };


    return (

        <Modal show={showModal} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Upload Track to Battle</Modal.Title>
            </Modal.Header>
            <Modal.Body>

                {loading && ( // Show loader if loading state is true
                    <div className="loader-container">
                        <div className="loader">
                            <BallTriangle
                                type="TailSpin"
                                color="#00BFFF"
                                height={50}
                                width={50}
                            />
                        </div>
                    </div>
                )}
                <div className="w-100">
                    <form className='battle-form' onSubmit={handleSubmit(onSubmit)}>
                        <div className={`choose-track-wrap ${watch('battleOption') === 'chooseFromMyTrack' ? 'active' : ''}`}>
                            <Form.Check
                                type="radio"
                                id="chooseFromMyTrack"
                                name="battleOption"
                                value="chooseFromMyTrack"
                                {...register('battleOption')}
                                label='Choose from my Library'
                            />


                            {watch('battleOption') === 'chooseFromMyTrack' && (
                                <>

                                    <Controller
                                        name="selectedTrack"
                                        control={control}
                                        render={({ field }) => (
                                            <Form.Select  {...field} >
                                                {trackList && trackList?.length > 0 ? <option value="">Select Track</option> : <option value="">No Track Available</option>}
                                                {trackList && trackList?.map((track) => (
                                                    <option key={track?._id} value={track?._id}>
                                                        {track?.track_name}
                                                    </option>
                                                ))}
                                            </Form.Select>
                                        )}
                                    />
                                    {errors?.selectedTrack && <p className="errorMsg">{errors?.selectedTrack?.message}</p>}

                                </>
                            )}

                        </div>
                        <div className={`add-new-track ${watch('battleOption') === 'addNewTrack' ? 'active' : ''}`}>
                            <Form.Check
                                type="radio"
                                id="addNewTrack"
                                name="battleOption"
                                value="addNewTrack"
                                {...register('battleOption')}
                                label='Add a new track'
                            />


                            {watch('battleOption') === 'addNewTrack' && (
                                <>
                                    <Form.Group className='file-upload-image  form-row' >


                                        {!image &&
                                            <label {...getRootPropsForImage()}  htmlFor="imageInput">
                                                <ImageIcon />
                                                <input  {...getInputPropsForImage()} accept="image/*" />

                                                {/* <input type="file"
                                                    id="imageInput"
                                                    accept="image/*"
                                                    onChange={(e) => {
                                                        setValue('imageInput', e.target.files);
                                                        setImage(URL.createObjectURL(e.target.files[0]));
                                                        clearErrors('imageInput');
                                                    }} /> */}
                                                <div className='file-upload-text'>
                                                    Choose image<br />
                                                    or drop it here
                                                </div>
                                            </label>
                                        }
                                        {/*Image upload functionality done here */}

                                        {image &&
                                            <div className='image-upload-holder'>
                                                <img src={image} alt='user' />
                                                <div className='delete-img-wrap' onClick={() => setImage(null)}>
                                                    <DeleteIcon />
                                                </div>
                                            </div>
                                        }

                                        {errors.imageInput && <div className="errorMsg">{errors.imageInput.message}</div>}
                                    </Form.Group>

                                    <Form.Group className='form-row file-upload-audio'>

                                        {/* <label htmlFor="fileInput">
                                            <input type="file" id="fileInput" accept=".mp3,.mp4" {...register('fileInput')} />

                                            <div className='icon-wrap'><TrackIcon /></div>
                                            <div className='file-upload-text'>Choose track or <br />
                                                drop it here <br />
                                                (in .mp3 format)</div>
                                        </label>
                                        {errors.fileInput && <div className="errorMsg">{errors.fileInput.message}</div>} */}



                                        {!selectedFile &&
                                            <>

                                                <label htmlFor="fileInput" {...getRootPropsForAudio()} className='file-upload'>
                                                    {/* <input type="file" id="fileInput" accept=".mp3,.mp4" onChange= {handleFileSelect } /> */}
                                                    <input {...getInputPropsForAudio()} />

                                                    <div className='icon-wrap'><TrackIcon /></div>
                                                    <div className='file-upload-text'>Choose track or <br />
                                                        drop it here <br />
                                                        (in .mp3 format)</div>
                                                </label>
                                            </>
                                        }
                                        {/*Remove Audion track name */}
                                        {selectedFile && <>
                                            <label >
                                                <div className='icon-wrap'><TrackIcon /></div>
                                                <div className='file-upload-text'>{selectedFile.name}
                                                </div>
                                                <div className='close-wrap' onClick={() => setSelectedFile(null)}>
                                                    <CloseIcon />
                                                </div>
                                            </label>
                                        </>}



                                        {errors.fileInput && <div className="errorMsg">{errors.fileInput.message}</div>}




                                    </Form.Group>

                                    <Form.Group className='form-row' controlId='trackNameInput'>
                                        <Form.Control type="text" id="trackNameInput" {...register('trackNameInput')} placeholder='Track Name' />
                                        {errors.trackNameInput && <div className="errorMsg">{errors.trackNameInput.message}</div>}
                                    </Form.Group>

                                    <Form.Group className='form-row'>
                                        <Form.Control as="textarea" id="trackDescriptionInput" {...register('trackDescriptionInput')} placeholder='Track Description' />
                                        {errors.trackDescriptionInput && <div className="errorMsg">{errors.trackDescriptionInput.message}</div>}
                                    </Form.Group>

                                    <Form.Group className='form-row'>
                                        <Form.Control type="text" id="genreSelect" {...register('genreSelect')} readOnly />
                                        {/* <Form.Select id="genreSelect" {...register('genreSelect')} disabled={isGenreDisabled}>
                                            <option value="">Select Genre</option>
                                            {genreList?.map((genre) => (
                                                <option key={genre?._id} value={genre?._id}>
                                                    {genre?.label}
                                                </option>
                                            ))}
                                        </Form.Select> */}
                                        {errors.genreSelect && <div className="errorMsg">{errors.genreSelect.message}</div>}
                                    </Form.Group>

                                    <Form.Group className='form-row'>
                                        <Form.Check
                                            type="checkbox"
                                            id="addToPlaylist"
                                            label="Add To My Library"
                                            checked={addToPlaylist}
                                            onChange={(e) => setAddToPlaylist(e.target.checked)}
                                        />
                                    </Form.Group>
                                </>
                            )}
                        </div>
                        {errors.battleOption && <p className="errorMsg">{errors.battleOption.message}</p>}

                        <div className='battle-btn-wrap'>
                            <Button variant="primary" type="submit">
                                Next
                            </Button>
                        </div>
                    </form>
                </div>
            </Modal.Body>
            <Modal.Footer>
            </Modal.Footer>
        </Modal>

    )
}

export default TrackToJoinBattle
