import React from 'react'
import * as cs from '../../Api/cs'

// import Header from '../../Component/Header/Header'
// import Sidebar from '../../Component/Sidebar/Sidebar'

import useHeader from '../../Helper/useHeader';

async function BattlePageServer () {

    const headers = useHeader()

    const response = await cs.get(`getById/675e767c5eac1e30dd2ffd11`, 'battel', {}, headers);


    console.log('response ', response)

    return (
        <div>
    
            {/* <Header /> */}
            {/* <Sidebar /> */}
           
           <div style={{ color: 'white'}}>Test 111</div>
        </div>
    )
}

export default BattlePageServer;
